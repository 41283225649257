<template>
    <div>
        <van-icon class="back-icon1" name="arrow-left" color="#fff" @click="goBack()"> </van-icon>
        <div class="header">
            <img class="logo1" :src="logo" alt="" />
        </div>
        <div class="content_records">
            <h2 class="titlerule">
                {{ $t('draw.lotteryRecords') }}
            </h2>
            <div v-if="records.length === 0" class="no-records">
                <p>{{ $t('draw.noRecords') }}</p>
            </div>
            <div class="record-list-draw" v-else>
                <ul>
                    <li v-for="record in records" :key="record.id" class="record-item">
                        <img :src="record.d_image" alt="Prize Image" class="record-image" />
                        <div class="record-details">
                            <p class="record-name">{{ record.d_name }}</p>
                            <p class="record-date">{{ record.create_time }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logo: require('../../assets/img/1.png'),
            records: [],
        };
    },
    mounted() {
        this.fetchLotteryRecords();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        fetchLotteryRecords() {
            this.$http({
                method: 'get',
                url: 'lottery_records'
            }).then(res => {
                if (res.code === 200) {
                    this.records = res.data;
                } else {
                    console.error("获取记录失败：", res.message);
                }
            }).catch(error => {
                console.error("接口调用失败：", error);
            });
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        },
    }
}
</script>

<style>
/* 全局样式 */
html {
    background-image: url('../../assets/img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.back-icon1 {
    font-size: 7vw;
    width: 50px;
    margin: 20px;
    cursor: pointer;
}

.logo1 {
    width: 70%;
    margin: 0 auto;
    padding-top: 100px;
    margin-bottom: 50px;
    display: block;
}

.content_records {
    width: 100%;
    text-align: center;
    height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.titlerule {
    font-size: 40px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px; 
}

.record-list-draw {
    width: 100%;
    overflow-y: auto; /* 允许垂直滚动 */
    max-height: 800px; /* 设置最大高度 */
}

.record-item {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3); 
    border-radius: 10px; 
    padding: 15px; 
    margin: 10px 0; 
    width: 100%; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
    transition: transform 0.2s; 
}

.record-item:hover {
    transform: translateY(-2px); 
}

.record-image {
    width: 120px; /* 增大图片宽度 */
    height: 120px; /* 增大图片高度 */
    border-radius: 10px; 
    margin-right: 15px; 
}

.record-details {
    color: #fff;
    flex-grow: 1; 
}

.record-name {
    font-size: 25px;
    font-weight: bold; 
    margin-bottom: 5px; 
}

.record-date {
    font-size: 25px;
    font-weight: bold; 

    color: #fff;
}

.no-records {
    color: #fff;
    text-align: center;
}
</style>
