<template>
    <div>
        <van-icon class="back-icon" name="arrow-left" color="#fff" @click="goBack()"> </van-icon>
        <div class="header">
            <img class="logo1" :src="logo" alt="" />
        </div>
        <div class="shop_details">
            <h3 class="shop_title">{{ details.title }}</h3>
            <!-- <p class="price">{{ $t("reservation.unit") }}{{ details.price }}</p> -->
            <img class="shop_img" :src="details.img" alt="" />
            <p class="details">{{ details.details }}</p>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            logo: require('../../assets/img/1.png'),
            details: {}
        }
    },
    created() {
        this.$http({
				method: 'post',
				url: 'shop_detail',
				data: {
					id: this.$route.query.id
				}
			}).then(res => {
				this.details = res.data
				console.info(res)
			})
    },
    methods: {
        goBack() {
            this.$router.go(-1); // 返回上一页面
        },
        home() {
            this.$router.push("LuckyDraw"); // 跳转到 LuckyDraw 页面
        }
    }
}
</script>

<style>
/* 全局样式 */
html {
    background-image: url('../../assets/img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; /* 背景图固定，不随内容滚动 */

}
.shop_details{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
}
.shop_img{
    width: 100%;
}
.back-icon {
    font-size: 7vw;
    width: 50px;
    /* 设置返回图标大小 */
    margin: 20px;
    /* 设置间距 */
    cursor: pointer;
    /* 鼠标悬停显示为手指 */
}

.logo1 {
    width: 70%;
    margin: 0 auto;
    padding-top: 100px;
    margin-bottom: 50px;
    display: block;
}
</style>