<template>
  <div class="lucky-draw">
    <div class="draw_num">
     {{$t("draw.drawNum", {num: drawNum})}}
    </div>
    <div class="lucky-wheel-container">
      <LuckyWheel ref="myLucky" width="300px" height="300px" :prizes="prizes" :blocks="blocks" :buttons="buttons"
        @start="startCallback" @end="endCallback" />
    </div>
    <div class="countdown">
      <p>{{ $t("draw.Countdown") }}:</p>
      <p>
        <span class="time">{{ days }}</span>{{ $t("draw.days") }} 
        <span class="time">{{ hours }}</span>{{ $t("draw.hours") }} 
        <span class="time">{{ minutes }}</span>{{ $t("draw.minutes") }} 
        <span class="time">{{ seconds }}</span>{{ $t("draw.seconds") }}
      </p>
    </div>
    
    <!-- 模态框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h4>{{ $t("draw.congratulationsOnWinning") }}!</h4>
        <p>{{ winner.name }}</p>
        <img :src="winner.img" alt="Winner Prize" class="winner-image" />
        <div class="modal-footer">
          <span class="close">{{ countdown }}{{ $t("draw.seconds") }}</span>
        </div>
      </div>
    </div>

    <!-- 跳转按钮 -->
    <div class="button-container">
      <button class="nav-button" @click="goToRules">{{ $t("draw.lotteryRules") }}</button>
      <button class="nav-button" @click="goToRecords">{{ $t("draw.lotteryRecords") }}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jsondata: [],
      blocks: [
        {
          padding: '10px',
          imgs: [{
            src: require('../../assets/img/light.png'),
            width: '100%',
            height: '100%'
          }]
        }
      ],
      prizes: [],
      buttons: [
        { radius: '30%', background: '#333333' },
        { radius: '25%', background: '#C0C0C0' },
        {
          radius: '20%', background: '#FFFFF0',
          pointer: true,
          fonts: [{ text: this.$t("draw.start"), top: '-10px', fontColor: '#333333', fontSize: '14px' }]
        }
      ],
      countdownEndTime: null,
      drawNum:0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      winner: null,
      showModal: false,
      countdown: 3,
      countdownTimer: null,
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http({
        method: 'get',
        url: 'draw'
      }).then(res => {
        if (res.code === 200) {
          this.jsondata = res.data.list.map(item => ({
            text: item.name,
            img: item.img
          }));
          this.drawNum = res.data.draw_num;
          this.updatePrizes();
          this.countdownEndTime = new Date(res.data.date);
          this.startCountdown();
        } else {
          this.$router.push("Login");
          this.$toast.fail(this.$t("auth.login"));
        }
      }).catch(error => {
        console.error("获取数据失败：", error);
      });
    },
    updatePrizes() {
      this.prizes = this.jsondata.map((item, index) => ({
        background: index % 2 === 0 ? '#d2d2d242' : '#FFFFF0',
        imgs: [{ src: item.img, width: '40%', top: '20%' }]
      }));
    },
    startCountdown() {
      this.countdownTimer = setInterval(() => {
        const now = new Date();
        const timeDiff = this.countdownEndTime - now;

        if (timeDiff > 0) {
          this.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          this.hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          this.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        } else {
          clearInterval(this.countdownTimer);
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
        }
      }, 1000);
    },
    startCallback() {
        this.$http({
          method: 'post',
          url: 'lottery_draw'
        }).then(res => {
          if (res.code === 200) {
            this.$refs.myLucky.play();
            this.winner = res.data;
            const prizeIndex = this.jsondata.findIndex(item => item.text === this.winner.name);
            if (prizeIndex !== -1) {
              this.$refs.myLucky.stop(prizeIndex);
            }
            setTimeout(() => {
          this.showModal = true;
          this.startCountdownTimer(); // 启动模态框倒计时
        }, 5000); // 2秒延迟
           
          } else {
            this.$toast.fail(res.msg);
            console.error("获取中奖产品失败：", res.msg);
          }
        }).catch(error => {
          console.error("接口调用失败：", error);
        });
    },
    async endCallback() {
      // 此方法可以省略
    },
    startCountdownTimer() {
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.countdownTimer);
          this.closeModal();
          this.drawNum--;
        }
      }, 1000);
    },
    closeModal() {
      clearInterval(this.countdownTimer);
      this.showModal = false;
      this.countdown = 3; // 重置倒计时
    },
    goToRules() {
      this.$router.push("lotteryRules");
    },
    goToRecords() {
      this.$router.push("lotteryRecords");
    },
  }
}
</script>

<style scoped>
.draw_num {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
}
.time {
  color: #fff;
  font-weight: 800;
}
.countdown {
  width: 100%;
  text-align: center;
  color: #fff;
}
.lucky-draw {
  z-index: 999;
}
.lucky-wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* 模态框样式 */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.modal-content {
  color: black;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}
.winner-image {
  max-width: 200px;
  max-height: 150px;
  margin-top: 10px;
}
.modal-footer {
  margin-top: 20px;
}
.close {
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  color: #000;
}

/* 按钮样式 */
.button-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}
.nav-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}
.nav-button:hover {
  background-color: #444;
}

@media (max-width: 768px) {
  .lucky-wheel-container {
    height: auto;
    padding: 20px;
  }
  LuckyWheel {
    width: 90vw;
    height: 90vw;
  }
}
</style>
