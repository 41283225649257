<template>
    <div class="movie-hall page">
      <van-nav-bar
          class="nav-bar"
          :title="$t('foorter.xiangshui')"
      />
      <!-- <van-tabs v-model="active" animated swipeable  @change="OnChange">
        <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" ></van-tab>
      </van-tabs> -->
      <swiper class="video_swiper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">
        <swiper-slide v-for="(v,key) in videolitem" :key="key">
          <div class="movie-list-tab">
            <van-pull-refresh :pulling-text="$t('ref.loading')" 
              :loosing-text="$t('ref.refresh')"
              :loading-text="$t('ref.loading')" 
              :success-text="$t('ref.success')" 
      v-model="isLoading" 
      @refresh="onRefresh">
              <div class="hot-recommend-div">
                  <van-list
                      v-model="loading"
                      :finished="finished"
                      :immediate-check="false"
                      :finished-text="$t('video.no_more')"
                      @load="onLoad"
                  >
                    <div class="list-item">
                      <div class="movie-list-item" v-for="(v,key) in shoplist" :key="key" @click="toShopDetail(v.id)">
                        <van-image class="cover_img"  round :src="v.img">
                          <template v-slot:loading>
                            <van-loading type="circular"/>
                          </template>
                        </van-image>
                        
                      </div>
                    </div>
                  </van-list>
  
              </div>
            </van-pull-refresh>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </template>
  
  <script>
  import { Toast } from 'vant';
  export default {
    data() {
      return {
        active: 0,
        isLoading: false,
        count:0,
        loading: false,
        finished: false,
        refreshing: false,
        shoplist: [],
        videolitem: [],
        videolist: [],
        number:0,
        page:0,
        videoSwiperOption: {
          slidesPerView: 'auto',
          spaceBetween: 0,
          slidesPerGroup : 1,
        }
      };
    },
    methods: {
      getVideoClass(){
        this.$http({
          method: 'get',
          url: 'video_class'
        }).then(res=>{
          this.videolitem = res.data;
        })
      },
      toPlayVideo(id){
        if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }else {
          this.$router.push({path:'/PlayVideo?id='+id})
        }
  
      },
      itemChange(){
        this.active = this.$refs.swiper.swiper.activeIndex
        this.OnChange()
      },
/*************  ✨ Codeium Command ⭐  *************/
      /**
       * Gets the list of videos for the current category
       * and appends it to the existing list
       */
/******  b745299b-fc42-433a-92f0-0c7c0e1fcda8  *******/
      getVideoList(){
        this.$http({
          method: 'get',
          data:{id:this.active,page:this.page},
          url: 'video_list'
        }).then(res=>{
          this.videolist = this.videolist.concat(res.data.data);
          this.count = res.data.count;
          this.page++;
  
        })
      },toShopDetail(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.$router.push({
					path: '/ShopDetails?id=' + id
				})
			}
		},
      onLoad() {
          this.getShopList();
        let timer = setTimeout(() => {
          if (this.refreshing) {
            this.videolist = [];
            this.refreshing = false;
          }
          this.loading = false;
          if (this.videolist.length === this.count) {
            this.finished = true;
          }
          this.finished && clearTimeout(timer);//清除计时器
        }, 500);
      },
       OnChange(){
        this.videolist = [];
        this.number = 0;
        this.page = 0;
        this.count = 0;
        // this.getVideoList();//获取视频列表
        this.getShopList(); //获取商城列表
  
      },
      getShopList() {
			this.$http({
				method: 'post',
				url: 'shop_list',
				data: {
					type: 2
				}
			}).then(res => {
				this.shoplist = res.data
				console.info(res)
			})
		},

      onRefresh() {
        setTimeout(() => {
          this.finished = false;
          this.loading = true;
          this.onLoad();
          this.isLoading = false;
          Toast(this.$t("reservation.refresh"));
        }, 500);
      },
    },
    created() {
      this.getVideoClass();//获取视频类目
      this.OnChange()
    }
  };
  </script>
  
  <style lang='less' scoped>
  .page{
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5;
  }
  .nav-bar{
    background: linear-gradient(
        90deg,#1C1C1C,#1C1C1C);
    height: 100px;
  
  }
  .van-nav-bar {
    line-height: 50px;
  }
  ::v-deep .van-tab {
      font-size: 30px;
      line-height: 100px;
      }
  
  ::v-deep .van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #ffffff;
    font-size: 35px;
  }
  ::v-deep .van-nav-bar__content {
    height: 100px;
  }
  
  .movie-hall{
    display: flex;
    flex-direction: column;
    bottom: 100px;
    background: #f2f2f5;
  }
  ::v-deep .van-tabs__nav {
    background: linear-gradient(
        90deg,#1C1C1C,#1C1C1C);
  }
  ::v-deep .van-tab {
    color: #ffffff;
    font-size: 30px;
  }
  ::v-deep .van-tabs__line {
    bottom: 15px;
    width: 55px;
    height: 7px;
    border-radius: 0px;
    background-color: #ffffff;
  }
  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 100px;
  }
  ::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
  }
  ::v-deep  .van-hairline--bottom::after {
    border-bottom-width: 0px;
  }
  .video_swiper {
    width: 100%;
    flex: 1;
    .swiper-slide {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 100%;
      justify-content: center;
      height: 100%;
      position: relative;
      transition-property: transform;
    }
  }
  .movie-list-tab {
    overflow: auto;
    height: 100%;
  }
  ::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
    color: #000;
    font-size: 35px;
  }
  .movie-list-tab .hot-recommend-div{
    height: 100%;
    margin: 10px auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    //overflow: auto;
  }
  .list-item{
    display: flex;
    width: calc(100% - 50px);
    margin: 10px auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .list-item .movie-list-item:nth-child(odd) {
    margin-right: 20px;
  }
  .movie-list-item .cover_img{
    border-radius: 20px;
    width:335px;
    height:290px;
  }
  .movie-list-item{
    margin-bottom: 10px;
  }
  .list-item .movie-list-item-bottom{
    position: relative;
    width: 335px;
    bottom: 42px;
  }
  .list-item .movie-list-item-bottom .movie-time-div{
    background-color: rgba(0,0,0,.4);
  }
  .list-item .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-item .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff;
  }
  .list-item .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    padding-left: 8px;
    font-size: 25px;
  }
  .list-item .movie-time-div {
    color: #fff;
    border-radius: 0 0 20px 20px;
    height: 35px;
  }
  </style>
  