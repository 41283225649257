<template>
  <div class="rule-wrap">
    <h2 class="titlerule">{{ $t('draw.lotteryRules') }}</h2>
    <div class="rule-con">{{ Rule }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Rule: '...', // 初始显示加载状态
    };
  },
  mounted() {
    this.fetchLotteryRules();
  },
  methods: {
    fetchLotteryRules() {
      this.$http({
        method: 'get',
        url: 'lottery_rules',
      })
        .then((res) => {
          if (res.code === 200) {
            this.Rule = res.data; // 假设接口返回的数据中规则文本是 `rule`
          } else {
            console.error("获取规则失败：", res.msg);
          }
        })
        .catch((error) => {
          console.error("接口调用失败：", error);
          this.Rule = '获取规则失败，请稍后重试。';
        });
    },
  },
};
</script>

<style>
.rule-wrap {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
}

.titlerule {
  font-size: 40px;
  text-align: center;
  color: #fff;
}

.rule-con {
  margin-top: 20px;
  padding: 0 30px;
  font-size: 30px;
  text-align: center;
  color: #fff;
  overflow-y: auto; /* 允许规则内容超出时滚动 */
  max-height: 70%; /* 设置最大高度，避免内容过长 */
}

.rule-con ul,
.rule-con ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  font-size: 32px;
  line-height: 45px;
}

.rule-con ul {
  list-style: disc;
}

.rule-con ol {
  list-style: decimal;
}

.rule-con h1,
.rule-con h2,
.rule-con h3,
.rule-con h4,
.rule-con h5,
.rule-con h6 {
  font-weight: 700;
}

.rule-con h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

.rule-con h2 {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.rule-con h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.rule-con h4 {
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

.rule-con h5 {
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  font-size: 0.85em;
}

.rule-con h6 {
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.rule-con p {
  margin: 1em;
}
</style>
