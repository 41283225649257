<template>
	<div>
		<img class="logo1" :src="logo" alt=""/>
		<LuckyDraw />
		<!-- <Rule /> -->
	</div>
</template>

<script>
import LuckyDraw from '../../common/components/LuckyDraw.vue'; // 确保路径正确
// import Rule from '../../common/components/Rule.vue';
export default {
	data() {
    return {
	logo: require('../../assets/img/1.png'),
	}
  },
	components: {
		LuckyDraw,
		// Rule
	}
}
</script>

<style>
/* 全局样式 */
html{
	background-image: url('../../assets/img/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	
}

#app {
	background: #00000066;

    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100vh; /* 确保高度使用视口高度 */

}
.logo1{
  width: 70%;
  margin: 0 auto;
  padding-top: 100px;
  margin-bottom: 50px;
  display: block;
}

</style>
