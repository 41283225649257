<template>
    <div>
        <van-icon class="back-icon" name="arrow-left" color="#fff" @click="goBack()"> </van-icon>
        <div class="header">
            <img class="logo1" :src="logo" alt="" />
        </div>
        <Rule/>
    </div>
</template>

<script>
import Rule from '../../common/components/Rule.vue';

export default {
    data() {
        return {
            logo: require('../../assets/img/1.png'),
        }
    },
    components: {
        Rule
    },
    methods: {
        goBack() {
            this.$router.go(-1); // 返回上一页面
        },
        home() {
            this.$router.push("LuckyDraw"); // 跳转到 LuckyDraw 页面
        }
    }
}
</script>

<style>
/* 全局样式 */
html {
    background-image: url('../../assets/img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.back-icon {
    font-size: 7vw;
    width: 50px;
    /* 设置返回图标大小 */
    margin: 20px;
    /* 设置间距 */
    cursor: pointer;
    /* 鼠标悬停显示为手指 */
}

.logo1 {
    width: 70%;
    margin: 0 auto;
    padding-top: 100px;
    margin-bottom: 50px;
    display: block;
}
</style>