<template>
	<div v-html="htmlContent"></div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			htmlContent: '',
		};
	},
	mounted() {
		axios.get('/chanel/index.htm')
			.then(response => {
				this.htmlContent = response.data;
			})
			.catch(error => {
				console.error('Error loading HTML:', error);
			});
	},
};
</script>